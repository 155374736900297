<template>
	<div>

		<Header :title="Header.Title" :subTitle="Header.SubTitle" :subTitle2="Header.SubTitle2" :bgUrl="Header.bgUrl"
			:bgUrlLazy="Header.bgUrlLazy" :offset="$vuetify.breakpoint.smAndDown ? '75vh' : '70vh'" :height="Header.height"
			:overlayOpacity="Header.overlayOpacity" :overlayColor="Header.overlayColor" :imgBlur="Header.imgBlur"
			:transitionRotate="Header.transitionRotate" />

		<v-row no-gutters class="pa-0 ma-0 d-flex justify-center">
			<Customers :class="$vuetify.breakpoint.smAndUp ? 'evidence' : ''" title='Clients' :cols="Dashboard.cols" :sm="Dashboard.sm" :md="Dashboard.md" :lg="Dashboard.lg"
				:xl="Dashboard.xl" :customers="customers" v-if="modules.customers" />
			<Companies :class="$vuetify.breakpoint.smAndUp ? 'evidence' : ''" title='Entreprises' :cols="Dashboard.cols" :sm="Dashboard.sm" :md="Dashboard.md"
				:lg="Dashboard.lg" :xl="Dashboard.xl" :companies="companies" v-if="modules.companies" />
		</v-row>

	</div>
</template>


<script>
	//const axios = require('axios');
	import Customers from '@/components/modules/@customer/CustomersTable';
	import Companies from '@/components/modules/@company/CompaniesTable';
	import Header from '../components/graphics/header'



	// import Funnel from '@/components/modules/Funnel';

	export default {
		head: {
			title: {
				inner: "Tableau de Bord"
			}
		},
		components: {
			Companies,
			Customers,

			Header,
			// Funnel
		},
		data() {
			return {
				userInfo: this.$models.user,
				backgroundImage: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?auto=format&fit=crop&w=1950",

				Header: {

					Title: '',

					SubTitle: 'Bienvenue sur votre espace Katana.',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "80vh",
					height: "75vh",

					overlayOpacity: "0",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				Dashboard: {
					cols: 12,
					sm: 12,
					md: 12,
					lg: 6,
					xl: 4,
				},

				customers: [],
				companies: [],

				modules: {
					customers: false,
					companies: false
				}
			}
		},

		created() {

			/*var config = {
				method: 'GET',
				headers: {
					'Accept': 'application/json'
				},
				params: {
					client_id: 'uswpQJXd2jEnD44udd8MRIMzzyRTzxE8IlMaYB-Iyko',
					orientation: 'landscape',
					query: 'mountains'
				}
			};
			axios("https://api.unsplash.com/photos/random", config).then((response) => {

				console.log(response.data)

				this.backgroundImage = response.data.urls.full

			});*/

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.Header.Title = `${this.userInfo.firstName},`

				this.$db.collection("companies").doc(doc.data().company).onSnapshot(async () => {
					this.modules.customers = await this.$functions.hasModule(this.userInfo,
						'8JJgD8UzYi1c6H3tukKF');
					this.modules.companies = await this.$functions.hasModule(this.userInfo,
						'MuIS4a0uWMkvYxL2o9v1');
				});

				this.$db.collection("companies").doc(this.userInfo.company).collection("customers").onSnapshot((
					res) => {
					this.customers = [];

					res.forEach((document) => {
						this.customers.push({
							...document.data(),
							id: document.id,
						});
					});
				});

				this.$db.collection("companies").doc(doc.data().company).collection("companies").onSnapshot((
					res) => {
					this.companies = [];

					res.forEach((document) => {
						this.companies.push({
							...document.data(),
							id: document.id,
						});
					});
				});
			});
		}
	}
</script>