<template>
	<div style="width: calc(100vw - 28px);">
		<v-card
			dark
			class="glassmorphism py-4 mt-4 px-4"
			:class="$vuetify.theme.dark ? 'dark' : 'light'"
			elevation="12"
			tile
		>
			<v-row
				no-gutters
				class="ma-0 pa-0"
			>
				<v-col
					class="ma-0 pa-0"
					cols="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
					v-if="$vuetify.breakpoint.smAndUp"
				>
					<Title
						weight="regular"
						color="white"
					>Évenements à venir</Title>
				</v-col>
				<v-col
					class="ma-0 pa-0"
					cols="12"
					sm="6"
					md="6"
					lg="6"
					xl="6"
				>
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						outlined
						text
						small
						class="float-right text-truncate ml-2"
						@click="dialogs.todo = true"
					>
						<v-icon small>
							mdi-plus
						</v-icon>
					</v-btn>
					<TaskAddDialog
						v-if="userInfo.company"
						v-model="dialogs.todo"
						:userInfo="userInfo"
						refreshTasks
						@init="init()"
					></TaskAddDialog>
					<v-btn
						:block="$vuetify.breakpoint.xsOnly"
						outlined
						text
						small
						class="float-right text-truncate"
						to="/tasks"
					>
						Voir plus
					</v-btn>
				</v-col>
			</v-row>
		</v-card>


		<v-card
			class="mb-5 glassmorphism"
			:class="$vuetify.theme.dark ? 'dark' : 'light'"
			dark
			elevation="12"
			height="60vh"
			width="100%"
			style="overflow-y: scroll; overflow-x: hidden;"
		>
			<v-card-text>
				<v-card
					color="transparent"
					elevation="0"
					class="pt-6"
					small
					v-for="item in tasks"
					:key="item.id"
				>

					<v-sheet
						height="1px"
						color="white"
						width="auto"
						style="opacity: 0.5;"
						class="mb-4 mx-8"
					>
					</v-sheet>

					<v-sheet
						style="position: absolute; left: 0; top: 0;"
						height="0"
						width="0"
						:color="item.category.color"
						v-if="item.dueDate"
					>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<div
									v-bind="attrs"
									v-on="on"
									class="pa-0 ma-0"
								>
									<v-avatar
										style="position: absolute; left: -7px; top: 18px;"
										:color="item.category.color"
										size="14"
									>
									</v-avatar>

								</div>
							</template>
							<span v-if="Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours())) == 0">En
								retard de
								{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asMinutes())) }}
								minutes</span>
							<span v-else-if="Math.round($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asDays()) == 0">En
								retard de
								{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours())) }}
								heures</span>
							<span v-else>En retard de
								{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asDays())) }}
								jours.</span>
						</v-tooltip>

					</v-sheet>

					<v-row
						class="pl-8"
						width="200px"
					>
						<v-col
							width="200px"
							cols="12"
							sm="10"
							md="10"
							lg="10"
							xl="10"
						>
							<strong
								v-if="item.dueDate && item.status != 3 && $moment(item.dueDate.toDate()).isBefore($moment(), 'minutes')"
								class="error--text"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
											class="pa-0 ma-0"
										>
											<!-- <v-icon left color="error">
												mdi-alert-circle-outline
											</v-icon>

											{{item.task}} -->

											<Title
												style="width: 100%;"
												weight="bold"
												titleSize="subtitle"
												titleColor="white"
												icon
												mdi="mdi-alert-circle-outline"
												iconColor="error"
												iconSize="regular"
											>{{ item.task }}</Title>

										</div>
									</template>
									<span v-if="Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours())) == 0">En
										retard de
										{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asMinutes())) }}
										minutes</span>
									<span v-else-if="Math.round($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asDays()) == 0">En
										retard de
										{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours())) }}
										heures</span>
									<span v-else>En retard de
										{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asDays())) }}
										jours.</span>
								</v-tooltip>
							</strong>
							<strong v-else-if="item.dueDate && item.status == 3">
								<!-- <v-icon dense color="success">mdi-check-circle-outline</v-icon>
								<span color="success">
									{{ item.task }}
								</span> -->
								<Title
									style="width: 100%;"
									weight="bold"
									titleSize="subtitle-2"
									titleColor="success"
									icon
									mdi="mdi-check-circle-outline"
									iconColor="success"
									iconSize="regular"
								>{{ item.task }}</Title>
							</strong>
							<strong
								v-else-if="item.dueDate && item.status != 3 && $moment(item.dueDate.toDate()).isSame($moment(), 'day')"
								class="warning--text"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
											class="text-truncate"
										>
											<v-icon
												dense
												color="warning"
											>
												mdi-clock-alert-outline
											</v-icon>
											{{ item.task }} <br><span class="font-weight-light">{{ $functions.getDateFormat(item.dueDate) }}</span>
										</div>
									</template>
									<span v-if="Math.round($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours()) > 1">{{ Math.round($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours()) }}
										heures restantes pour effectuer cette tâche.</span>
									<span v-else-if="Math.floor($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asHours()) == 1">1
										heure restante pour effectuer cette tâche.</span>
									<span v-else>{{ Math.round($moment.duration($moment(item.dueDate.toDate()).diff($moment())).asMinutes()) }}
										minutes restantes pour effectuer cette tâche.</span>
								</v-tooltip>
							</strong>
							<!-- <strong v-else class="text-truncate">{{ item.task }}
								
								<br>
								<span
									class="font-weight-light">{{ $functions.getDateFormat(item.dueDate) }}</span>
							</strong> -->



							<Title
								v-else
								style="width: 100%;"
								weight="bold"
								titleSize="subtitle-2"
								titleColor="white"
								icon
								mdi="mdi-login"
								iconColor="white"
								iconSize="regular"
							>{{ item.task }}</Title>

							<div class="caption">
								Priorité : {{ $listUtils.priority[item.priority] }} /
								Statut : {{ $listUtils.elementStatus[item.status] }}
							</div>

							<Participants
							class="mb-8"
								title
								:participantsData="item.participantsData"
							/>


							<div
								class="caption mt-2"
								v-if="item.projectData"
							>
								Projet
							</div>

							<div v-if="item.projectData">
								<v-menu
									bottom
									min-width="200px"
									rounded
									offset-y
									:close-on-content-click="false"
								>
									<template v-slot:activator="{ on }">
										<v-btn
											icon
											v-on="on"
										>
											<v-avatar size="30">
												<span
													v-if="!item.projectData.avatar && item.projectData.title"
													class="font-weight-regular"
												>
													{{ item.projectData.title[0] }}.
												</span>
												<v-img
													v-else
													:src="$functions.getImgLink(item.projectData.avatar, 50)"
												></v-img>
											</v-avatar>
										</v-btn>
									</template>
									<v-card elevation="16">
										<v-list-item-content class="justify-center">
											<div class="mx-auto text-center">
												<v-avatar
													class="mb-2"
													size="64"
												>
													<span
														v-if="!item.projectData.avatar && item.projectData.title"
														class="font-weight-regular"
													>
														{{ item.projectData.title[0] }}.
													</span>
													<v-img
														v-else
														:src="$functions.getImgLink(item.projectData.avatar, 50)"
													></v-img>
												</v-avatar>

												<h3>{{ item.projectData.title }}</h3>

												<v-divider class="my-3"></v-divider>

												<v-btn
													outlined
													@click="$functions.goTo(`record/project/${item.projectData.id}`)"
												>
													Voir la fiche
												</v-btn>
											</div>
										</v-list-item-content>
									</v-card>
								</v-menu>
							</div>


						</v-col>
						<v-col
							cols="12"
							sm="2"
							md="2"
							lg="1"
							xl="1"
						>
							<v-btn
								text
								icon
								v-if="item.status == 1 || item.status == 0"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
										>
											<v-icon
												:color="$cssGlobal.getStatus($listUtils.elementStatus[3])"
												@click="statusTask(item, 2), item.status = 2"
											>mdi-play</v-icon>
										</div>
									</template>
									<span>
										<span>Démarrer la tâche</span>
									</span>
								</v-tooltip>
							</v-btn>
							<v-btn
								text
								icon
								v-if="item.status == 2"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
										>
											<v-icon
												:color="$cssGlobal.getStatus($listUtils.elementStatus[3])"
												@click="statusTask(item, 3), item.status = 3"
											>mdi-check-bold</v-icon>
										</div>
									</template>
									<span>
										<span>Terminer la tâche</span>
									</span>
								</v-tooltip>
							</v-btn>
							<v-btn
								text
								icon
								v-if="item.status == 2"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on"
										>
											<v-icon
												:color="$cssGlobal.getStatus($listUtils.elementStatus[2])"
												@click="statusTask(item, 1), item.status = 1"
											>mdi-pause</v-icon>
										</div>
									</template>
									<span>
										<span>Mettre la tâche en attente</span>
									</span>
								</v-tooltip>
							</v-btn>
						</v-col>
					</v-row>



				</v-card>

				<!-- FAUT PAS SUPPRIMER CET ITEM, USEFUL POUR LES PARTICIPANTS OU CLIENTS RÉFÉRÉS DANS LES TÂCHES
									
								<v-timeline-item color="blue" small>
									<v-row class="pt-1">
										<v-col cols="3">
											<strong>18:00</strong>
										</v-col>
										<v-col>
											<strong>Appeler Stan</strong>
											<div class="caption">
												Bosser ensemble et rigoler aussi
											</div>
											<div class="caption mb-2">
												Participants
											</div>
											<v-avatar>
												<v-img
													src='https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortRound&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Twinkle&skinColor=Pale'>
												</v-img>
											</v-avatar>
											<v-avatar>
												<v-img
													src='https://avataaars.io/?avatarStyle=Circle&topType=LongHairCurly&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=Blue02&eyeType=Default&eyebrowType=Default&mouthType=Smile&skinColor=Brown'>
												</v-img>
											</v-avatar>
										</v-col>
									</v-row>
								</v-timeline-item>-->




			</v-card-text>


		</v-card>
	</div>
</template>


<style :scoped="$vuetify.breakpoint.width > 500">
	::-webkit-scrollbar {
		width: 4px;
		/* width of the entire scrollbar */
	}

	::-webkit-scrollbar-track {
		background: transparent;
		/* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background-color: white;
		/* color of the scroll thumb */
		border-radius: 4px;
		/* roundness of the scroll thumb */
		/* border: 1px solid black; */
		/* creates padding around scroll thumb */
	}
</style>



<script>
	import Title from '@/components/graphics/title'
	import TaskAddDialog from '@/components/modules/@tasks/TaskAddDialog';
	import Participants from '@/components/modules/#global/Participants'

	export default {
		components: {
			Title,
			TaskAddDialog,
			Participants,
		},
		data() {
			return {
				userInfo: this.$models.user,
				tasks: [],
				dialogs: {
					todo: false,
					genQR: false
				},
			}
		},
		methods: {
			statusTask(task, status) {
				this.$db.collection("companies").doc(this.userInfo.company).collection("tasks").doc(task.id).set({
					'lastUpdate': new Date(),
					'status': status
				}, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `La tâche ${task.task} a été modifiée !`,
					});
				});
			},
			init() {
				const ref = this.$db.collection("companies").doc(this.userInfo.company).collection("tasks");
				this.$functions.getTasks(this.userInfo.company, ref, [1, 2], 10).then((value) => {
					this.tasks = value;
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.init();
			});
		}
	}
</script>