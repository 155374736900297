<template>
	<div
		style="min-height: 50px;"
		v-if="participantsData && participantsData.length"
	>
		<Title
			v-if="title"
			weight="regular"
			titleSize=""
			class=""
		>Participants :</Title>
		<v-menu
			bottom
			min-width="200px"
			rounded
			offset-y
			:close-on-content-click="false"
			v-for="(participant, index) in participantsData.slice(0, 5)"
			:key="participant.id"
		>
			<template v-slot:activator="{ on }">
				<v-btn
					height="36"
					width="36"
					class="evidence mt-5 ml-3"
					:style="'position: absolute; left: ' + ((index + 0.5) * 20) + 'px;'"
					icon
					v-on="on"
				>
					<v-avatar
						size="26"
						:style="'background-color: #' + $functions.convertToHex(participant.email) + ';'"
					>
						<span
							v-if="!participant.avatar && participant.firstName"
							class="font-weight-regular white--text"
						>
							{{ participant.firstName[0] }}{{ participant.lastName[0] }}
						</span>
						<span
							v-else-if="!participant.avatar && participant.name"
							class="font-weight-regular white--text"
						>
							{{ participant.name[0] }}
						</span>
						<v-img
							v-else
							:src="$functions.getImgLink(participant.avatar, 50)"
						></v-img>
					</v-avatar>
				</v-btn>
			</template>
			<v-card elevation="16">
				<v-list-item-content class="justify-center">
					<div class="mx-auto text-center">
						<v-avatar
							class="mb-2"
							size="64"
							:style="'background-color: #' + $functions.convertToHex(participant.email) + ';'"
						>
							<span
								v-if="!participant.avatar && participant.firstName"
								class="font-weight-regular display-1 white--text"
							>
								{{ participant.firstName[0] }}{{ participant.lastName[0] }}
							</span>
							<span
								v-else-if="!participant.avatar && participant.name"
								class="font-weight-regular display-1 white--text"
							>
								{{ participant.name[0] }}
							</span>
							<v-img
								v-else
								:src="$functions.getImgLink(participant.avatar, 50)"
							></v-img>
						</v-avatar>
						<h3 v-if="participant.firstName">
							{{ participant.firstName }} {{ participant.lastName }}
						</h3>
						<h3 v-else>{{ participant.name }}</h3>
						<p
							class="caption mt-1"
							v-if="participant.email"
						>
							{{ participant.email }}
						</p>
						<template v-if="participant.report != false">
							<v-btn
								outlined
								small
								:color="$vuetify.theme.dark ? 'white' : 'primary'"
								@click="$functions.goTo(`record/${participant.name ? 'company' : 'customer'}/${participant.id}`)"
							>
								Voir la fiche
							</v-btn>
						</template>
					</div>
				</v-list-item-content>
			</v-card>
		</v-menu>
		<v-btn
			v-if="participantsData && participantsData.length > 5"
			height="24"
			width="24"
			class="mt-9 ml-4"
			outlined
			small
			:style="'position: absolute; left: ' + (((index + 0.5) * 20) + 15) + 'px;'"
			icon
			@click="dialog = true"
		>
			<v-avatar
				size="26"
				icon
			>
				<v-icon>mdi-dots-horizontal</v-icon>
			</v-avatar>
		</v-btn>

		<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			max-width="960"
		>

				<ButtonTrigger
					title="Participants"
					titleSize="h6"
					small
					whiteText
					:noButton="$vuetify.breakpoint.smAndUp"
					icon
					content=""
					@click.native="dialog = false"
				/>

				<v-col cols="12">
					<v-container>
						<v-row>
							<v-col
								class="pa-4"
								cols="4"
								v-for="(participant) in participantsData"
								:key="participant.id"
							>
								<v-row>
									<v-card
										width="250"
										height="200"
										elevation="16"
									>
										<v-list-item-content class="justify-center">
											<div class="mx-auto text-center">
												<v-avatar
													class="mb-2"
													size="64"
													:style="'background-color: #' + $functions.convertToHex(participant.email) + ';'"
												>
													<span
														v-if="!participant.avatar && participant.firstName"
														class="font-weight-regular display-1 white--text"
													>
														{{ participant.firstName[0] }}{{ participant.lastName[0] }}
													</span>
													<span
														v-else-if="!participant.avatar && participant.name"
														class="font-weight-regular display-1 white--text"
													>
														{{ participant.name[0] }}
													</span>
													<v-img
														v-else
														:src="$functions.getImgLink(participant.avatar, 50)"
													></v-img>
												</v-avatar>
												<h3 v-if="participant.firstName">
													{{ participant.firstName }} {{ participant.lastName }}
												</h3>
												<h3 v-else>{{ participant.name }}</h3>
												<p
													class="caption mt-1"
													v-if="participant.email"
												>
													{{ participant.email }}
												</p>
												<template v-if="participant.report != false">
													<v-btn
														small
														outlined
														class="evidence absolute bottom center mb-8"
														:color="$vuetify.theme.dark ? 'white' : 'primary'"
														@click="$functions.goTo(`record/${participant.name ? 'company' : 'customer'}/${participant.id}`)"
													>
														Voir la fiche
													</v-btn>
												</template>
											</div>
										</v-list-item-content>
									</v-card>

								</v-row>
							</v-col>
						</v-row>
					</v-container>

				</v-col>
		</v-dialog>


		<!-- <v-avatar
			:style="'position: absolute; left: ' + (participantsData.length * 4) + 'px;'"
			size="30"
			v-if="participantsData && participantsData.length > 5"
		>
			<v-icon>mdi-dots-horizontal</v-icon>
		</v-avatar> -->
	</div>
</template>

<script>
	import Title from '@/components/graphics/title';
	import ButtonTrigger from '@/components/graphics/buttonTrigger'


	export default {
		props: {
			id: String,
			participantsData: {
				type: Array,
				default: null
			},
			title: {
				type: Boolean,
				default: false,
			},

		},

		components: {
			Title,
			ButtonTrigger,

		},

		data() {
			return {
				dialog: false,
			}
		},



	}
</script>