<template>

	<div>

		<v-row class="mb-12 pa-0 ma-0" style="width: 100%; position: absolute; top: 0px; left: 0px;">

			<v-img :src="$vuetify.theme.dark ? `${bgUrl}` : `${bgUrlLight}`"
				:lazy-src="$vuetify.theme.dark ? `${bgUrlLazy}`  : `${bgUrlLightLazy}`" :height="`${height}`"
				width="100vw" style="position: absolute; top: 0; left: 0;"
				:style="'filter: blur(' + `${imgBlur}` + ');'">
			</v-img>

			<v-sheet :color="overlayColor" :height="`${height}`" width="100vw"
				style="position: absolute; top: 0; left: 0;" :style="'opacity:' + `${overlayOpacity}` + ';'"></v-sheet>

			<v-sheet color="transparent" :height="`${height}`" width="100vw" style="z-index: 0;">

				<v-row>

					<v-col class="px-6" cols="12" sm="12" md="6" lg="4" xl="4">
						<TasksTimeline :class="$vuetify.breakpoint.lgOnly ? 'mr-auto' : 'mx-auto'" style="max-width: 600px;" />
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="8" xl="8">

						<v-row v-if="$vuetify.breakpoint.mdAndUp">

							<v-col cols="12" md="12">

								<h3 class="white--text float-right font-weight-light font-italic mt-8 mr-8">
									{{subTitle}}
									<br>
									{{subTitle2}}
								</h3>

							</v-col>

							<v-col cols="12" md="12">

								<h1 class="white--text float-right font-weight-light mr-8"
									style="font-size: min(max(6vw, 40px), 72px);">
									{{ currentTime }}</h1>

							</v-col>

						</v-row>

					</v-col>

				</v-row>

				<Transition transitionPosition="bottom" :transitionRotate="transitionRotate" />

			</v-sheet>

		</v-row>

		<!-- v-if="$vuetify.breakpoint.width > 450" -->
		<v-sheet color="transparent" :height="`${offset}`" class="ma-0 pa-0"></v-sheet>

	</div>

</template>


<script>
	import TasksTimeline from '@/components/modules/@tasks/TasksTimeline';
	import Transition from '@/components/graphics/transition';

	export default {
		props: {
			'id': {
				type: String
			},

			title: String,
			subTitle: String,
			subTitle2: String,
			'bgUrl': {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg',
			},
			'bgUrlLight': {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg',
			},

			'bgUrlLazy': {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg',
			},
			'bgUrlLightLazy': {
				type: String,
				default: 'https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg',
			},
			offset: String,
			height: String,
			'overlayOpacity': {
				type: String,
				default: '0',
			},
			overlayColor: String,
			imgBlur: String,
			transitionRotate: String,

		},
		components: {
			TasksTimeline,
			Transition,
		},
		data() {
			return {

				message: "Current Time:",
				currentTime: null



			};
		},


		methods: {
			updateCurrentTime() {
				this.currentTime = this.$moment().format("HH:mm:ss");
			}
		},

		created() {
			this.currentTime = this.$moment().format("HH:mm:ss");
			setInterval(() => this.updateCurrentTime(), 1 * 1000);
		},
	}

</script>
